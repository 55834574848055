import request from "@/utils/request.js";

/**
 * @description: 缴费
 */
interface IPay {
  recordId: number,
  chargingVoucher: string,
  payType: number
}
export function pay(params: IPay) {
  return request({
    url: "/api/waterPayment/PayCashBill",
    method: "post",
    data: params,
  });
}
/**
 * @description: 缴费
 */
interface IBindInvoice {
  billId: number[],
  invoice: string,
  description?: string
}
export function bindInvoice(params: IBindInvoice) {
  return request({
    url: "/api/waterPayment/BindInvoice",
    method: "post",
    data: params,
  });
}

/**
 * @description: 发票作废并取消账单
 */
export function unbindInvoice(invoice: string) {
  return request({
    url: "/api/waterPayment/UnbindInvoice",
    method: "post",
    data: { invoice },
  });
}