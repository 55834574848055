import request from "@/utils/request";

/**
 * @description: 查找水表（有权限范围，主要用于公众号端）
 */
interface IListWaterUnits {
  Name?: string,
  Paid?: boolean | string,
  AreaId?: number | string,
  MaxCount?: number,
  SkipCount: number,
}
export function listWaterUnits(query: IListWaterUnits) {
  return request({
    url: "/api/WaterAggregation/ListWaterUnits",
    method: "get",
    params: query,
  });
}




/**
 * @description: 查找水表记录
 */

interface IListWaterUnitRecordsByInfo extends IListWaterUnits {
  BillingType: number
}
export function listWaterUnitRecordsByInfo(query: IListWaterUnitRecordsByInfo) {
  return request({
    url: "/api/WaterAggregation/ListWaterUnitRecordsByInfo",
    method: "get",
    params: query,
  });
}




/**
 * @description: 查询抄表记录,通过发票编号查询，InvoiceIds为null则查询所有，否则按照指定查询
 */
export function listWaterUnitRecordsByInvoice(query: any) {
  return request({
    url: "/api/WaterAggregation/ListWaterUnitRecordsByInvoice",
    method: "get",
    params: query,
  });
}


/**
 * @description: 查询抄表记录,通过通知单查询，ISheetIds为null则查询所有，否则按照指定查询
 */
export function listWaterUnitRecordsByNoticeSheet(query: any) {
  return request({
    url: "/api/WaterAggregation/ListWaterUnitRecordsByNoticeSheet",
    method: "get",
    params: query,
  });
}


/**
 * @description: 查询抄表记录,通过通知单查询，ISheetIds为null则查询所有，否则按照指定查询
 */
export function listWaterUnitRecordsByChargingVoucher(query: any) {
  return request({
    url: "/api/WaterAggregation/ListWaterUnitRecordsByChargingVoucher",
    method: "get",
    params: query,
  });
}


/**
 * @description: 查询发票
 */
export function listWaterInvoice(query: any) {
  return request({
    url: "/api/WaterAggregation/ListWaterInvoice",
    method: "get",
    params: query,
  });
}



/**
 * @description: 获取最近的数据统计
 */
export function getStatistic(query: any) {
  return request({
    url: "/api/WaterAggregation/GetStatistic",
    method: "get",
    params: query,
  });
}


/**
 * @description: 获取最近手动添加的记录
 */
export function getManaulRecordList(query: any) {
  return request({
    url: "/api/WaterAggregation/GetManaulRecordList",
    method: "get",
    params: query,
  });
}