import dayjs from "dayjs";

/**
 * @description: 保留小数
 * @param val 数字
 * @param flag 是否从分转元
 * @param num 保留几位小数
 * @return {*} ￥2.00
 */
export function keepDecimal(val, flag = true, num = 2) {
  if (flag) {
    val = amend(val);
  }
  return "￥" + val.toFixed(num);
}

/**
 * @description:
 * @param val 价格
 * @return int
 */
export function removeDecimal(val) {
  return amend(val, 100, "*");
}

//js 小数的计算
export function amend(num1, num2 = 100, symbol = "/") {
  if (num1 === undefined) {
    return;
  }
  var str1 = num1.toString(),
    str2 = num2.toString(),
    result,
    str1Length,
    str2Length;

  //解决整数没有小数点方法

  //获取小数的位数
  try {
    str1Length = str1.split(".")[1].length;
  } catch (error) {
    str1Length = 0;
  }
  try {
    str2Length = str2.split(".")[1].length;
  } catch (error) {
    str2Length = 0;
  }

  var step = Math.pow(10, Math.max(str1Length, str2Length));

  let sum1 = parseInt(num1 * step);
  let sum2 = parseInt(num2 * step);

  switch (symbol) {
    case "+":
      result = (sum1 + sum2) / step;
      break;
    case "-":
      result = (sum1 - sum2) / step;
      break;
    case "*":
      result = (sum1 * sum2) / step / step;
      break;
    case "/":
      result = sum1 / sum2;
      break;
    default:
      break;
  }
  return result;
}

// .toString()
//日期格式化
export function formatDate(val) {
  return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
}

//如果对象的key没有值就去掉
export function removeObjNullValue(obj) {
  let newObj = {};
  for (let key in obj) {
    if (obj[key] !== "" && obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

